import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)(({ theme }) => ({
    width: "fit-content",
    padding: theme.spacing(1.5, 4),
    color: theme.palette.background.default,
    textTransform: "none",
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
        opacity: 0.8,
    },
    [theme.breakpoints.down("md")]: {
        width: "100%",
        height: 45,
    },
}));

const ButtonStyled = ({
    type,
    style,
    label,
    icon,
    disabled,
    onClick = () => {},
    ...props
}) => {
    return (
        <ColorButton
            disabled={disabled}
            variant={"contained"}
            style={style}
            type={type}
            onClick={onClick}
            startIcon={icon}
            {...props}
        >
            {label}
        </ColorButton>
    );
};

export default ButtonStyled;
