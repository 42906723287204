import React from "react";
import orangeV2 from "../../../assets/images/orangeV2.png"
import expressunion from "../../../assets/images/expressunion.png"
import yoomee from "../../../assets/images/yoomee.png"
import mtn from "../../../assets/images/mtn.png"

function createData(id,
                    provider,
                    description,
) {
    return {
        id,
        provider,
        description,
    };
}

export const data = [
    createData(1, <img src={orangeV2} alt={'orangeV2'}
                       width="70"/>, 'orangeInstructions',),
    createData(2, <img src={mtn} alt={'mtn'}
                       width="70"/>, 'mtnInstructions',),
    createData(3, <img src={expressunion} alt={'expressunion'}
                       width="70"/>, 'euInstructions',),
    createData(4, <img src={yoomee} alt={'yoomee'}
                       width="70"/>, 'yoomeeInstructions',),

];