import { useEffect } from "react";
import LocalizedStrings from "react-localization";
import localization from "../localization";

export default function useTranslation() {
  let translation = new LocalizedStrings(localization);
  useEffect(() => {
    // Get the browser language
    const browserLanguage =
      navigator.language || navigator.userLanguage || "en";
    translation.setLanguage(browserLanguage);
  }, []);

  return translation;
}
