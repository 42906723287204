import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import useStyles from "./styles";
import OrderColumns from "../../../../helpers/OrderColumns";
import CardGrid from "../../../../components/CardGrid";
import CardFooterRow from "../CardFooterRow";
import palette from "../../../../styles/palette";

const OrdersCardDesktop = ({ data, items, amountsData, theme }) => {
    const classes = useStyles();

    return (
        <>
            <Grid className={classes.cardHeader} sx={{ backgroundColor: theme?.primaryColor ? theme.primaryColor : palette.primary.main }} container direction="column">
                <Typography variant="b2" style={{ fontWeight: 600 }}>Détails de la Commande</Typography>
                <Typography variant="b3" style={{ fontWeight: 600 }}>Order Details</Typography>
            </Grid>
            <Grid className={classes.gridWrapper}>
                <CardGrid
                    rows={items}
                    columns={OrderColumns}
                    currency={data?.currency}
                />
            </Grid>
            <Box className={classes.footer} sx={{ backgroundColor: theme?.secondaryColor ? theme.secondaryColor : palette.background.grey }}>
                {amountsData.map((item) => (
                    <CardFooterRow
                        item={item}
                        rowHeight={45}
                        typographyVariant="t4"
                        lastItemTypographyVariant="b7"
                        key={item.name}
                    />
                ))}
            </Box>
        </>
    );
};

export default OrdersCardDesktop;
