import { makeStyles } from "@mui/styles";
import palette from "../../../../styles/palette";

const useStyles = makeStyles((theme) => ({
    cardHeader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: 50,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
    },
    columnNames: {
        height: 40,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        borderBottom: `1px solid ${theme.palette.border.lightGray}`,
    },
    footer: {
        backgroundColor: palette.background.grey,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
}));

export default useStyles;
