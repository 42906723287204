import React from "react";
import { Grid, Typography, Tooltip } from "@mui/material";
import { theme } from "../../../../styles/theme";
import { trimText } from "../../../../helpers/trimText";

const OrderRow = ({ item, currency }) => {
    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{
                paddingLeft: theme.spacing(1.5),
                paddingRight: theme.spacing(1.5),
                height: 35,
                borderBottom: `1px solid ${theme.palette.border.lightGray}`,
            }}
        >
            <Tooltip title={item?.particulars}>
                <Typography variant="t4">{trimText(item?.particulars)}</Typography>
            </Tooltip>
            <Typography variant="t4">
                {item?.subTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}{" "}
                {currency === "XAF" ? "FCFA" : currency}
            </Typography>
        </Grid>
    );
};

export default OrderRow;
