import React, { useContext, useState } from "react";
import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { ReactComponent as Logo } from "../../assets/images/logoDesktop.svg";
import PoweredBy from "../../assets/images/poweredBy.svg";

import { GlobalContext } from "../../context/GlobalContext";
import palette from "../../styles/palette";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0 auto",
        height: 86,
        display: "flex",
        justifyContent: "center",
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: "100%",
        backgroundColor: theme.palette.background.light,
    },
    logo: {
        height: "100%",
        width: 187,
    },
    right: {
        display: "flex",
        flexDirection: "column",
    },
    container: {
        display: "flex",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        flexBasis: "66.666667%",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
    },
    poweredBy: {
        width: 158,
        height: "100%",
    },
    button: {
        fontSize: '0.875rem !important',
        minWidth: "1rem !important",
        height: 24,
    },
}));

const HeaderDesktop = () => {
    const { theme, logo } = useContext(GlobalContext);
    const classes = useStyles();

    return (
        <>
            <Grid className={classes.root} sx={{ backgroundColor: theme?.headerColor ? theme.headerColor : palette.background.light }} position="static">
                <Grid
                    className={classes.container}
                    item
                    xl={10}
                    md={12}
                    xs={12}
                >
                    {logo ? <img className={classes.logo} src={`data:image/png;base64,${logo}`} alt="Merchant Logo" />: <Logo className={classes.logo} />}
                    <Box item className={classes.right}>
                        <img src={PoweredBy} className={classes.poweredBy} />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};
export default HeaderDesktop;
