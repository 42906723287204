import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import Flag from "./utils/Flag";
import AutocompleteCountryOption from "./utils/AutocompleteCountryOption";

const FormAutoComplete = ({
  options = [],
  type,
  variant,
  label,
  loading,
  readOnly,
  dataTestId,
  defaultValue,
  size,
  onChange,
  selectedOption,
  ...rest
}) => {

  const handleRenderInput = (params) => {
    switch (type) {
      case "country":
        return (
          <TextField
            {...params}
            variant={variant}
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: selectedOption ? (
                <React.Fragment>
                  <Box sx={{ marginRight: 1, marginLeft: 1 }}>
                    <Flag code={String(selectedOption?.code?.toLowerCase())} />
                  </Box>
                  {params.InputProps.startAdornment}
                </React.Fragment>
              ) : null,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            size={size}
          />
        );

      default:
        return <TextField {...params} variant={variant} label={label} />;
    }
  };

  return (
    <Autocomplete
      id="country-select-demo"
      readOnly={readOnly}
      data-testid={dataTestId}
      defaultValue={defaultValue}
      onChange={(_, newValue) => onChange(newValue)}
      options={options}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{
            "& > img": { mr: 2, display: "flex", flexShrink: 0 },
          }}
          {...props}
        >
          {type === "country" ? (
            <AutocompleteCountryOption option={option} />
          ) : (
            option?.name
          )}
        </Box>
      )}
      renderInput={handleRenderInput}
      loading={loading}
      {...rest}
    />
  );
};

export default FormAutoComplete;
