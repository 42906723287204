import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    formHeader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: 50,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
    },
    rootForm: {
        border: `1px solid ${theme.palette.border.default}`,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(2.25),
        paddingBottom: theme.spacing(3),
    },
    marker: {
        fontWeight: "bold",
        fontSize: 14,
        color: theme.palette.red,
        transform: "rotate(0.1deg)",
    },
    fieldTitle: {
        marginBottom: theme.spacing(0.5),
    },
    fieldset: {
        display: "flex !important",
        flexDirection: "row !important",
        flexWrap: "wrap !important",
        alignItems: 'center',
        justifyContent: "space-between"
    },
    fieldTitleSecondary: {
        color: theme.palette.text.primary,
        paddingBottom: theme.spacing(1),
    },
    radioGroupContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between"
    },
    radioBlockWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    radio: {
        padding: `${theme.spacing(0.5)} !important`,
        "& .MuiSvgIcon-root": {
            height: 10,
            width: 10,
            color: theme.palette.primary.main,
        },
    },
    providerImg: {
        display: 'inline-block',
        verticalAlign: 'middle',
        height: "auto",
        width: 45,
        borderRadius: 5,
    },
    inputWrapper: {
        paddingBottom: theme.spacing(3),
        "& .MuiFormControl-root": {
            marginTop: "0 !important",
        },
        "& .MuiFormHelperText-root.Mui-error": {
            fontSize: 10,
            marginLeft: theme.spacing(1),
        },
    },
    input: {
        minWidth: "100%",
        "& .MuiInputBase-input": {
            border: `0.5px solid ${theme.palette.border.default}`,
            borderRadius: 3,
            padding: 0,
            paddingLeft: theme.spacing(1),
            fontSize: "1rem",
            width: "100%",
            height: 32,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                border: `0.5px solid ${theme.palette.border.default}`,
                borderRadius: 3,
            },
            "&:hover fieldset": {
                border: `1px solid ${theme.palette.border.main}`,
            },
            "&.Mui-focused fieldset": {
                border: `1px solid ${theme.palette.border.main}`,
                borderWidth: 1,
            },
        },
    },
    icon: {
        height: 30,
        width: "auto",
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    pnoheNumberFormControl: {
        width: "100%",
        "& .invalid-number-message": {
            fontSize: "0.625rem",
        },
    },
    checkBox: {
        "& .MuiCheckbox-root": {
            fontSize: "1.5rem",
            color: theme.palette.primary.main,
        },
        "& .MuiTypography-root": {
            fontSize: "0.75rem",
        },
    },
    checkBoxWrapper: {
        paddingTop: theme.spacing(3),
    },
    link: {
        "& .MuiLink-root": {
            color: theme.palette.text.secondary,
            textDecoration: "none",
        },
    },
    buttonContainer: {
        marginTop: theme.spacing(4),
    },
}));

export default useStyles;
