import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { getIn } from "formik";
import PropTypes from "prop-types";

function FormSelectInput({
  name,
  label,
  type = "normal",
  formik,
  options,
  defaultValue,
  ...props
}) {
  return (
    <FormControl fullWidth size="small">
      <InputLabel
        id="demo-select-small-label"
        sx={{
          fontSize: 13,
          backgroundColor: "#fff",
          paddingX: "10px",
          paddingTop: "7px",
          color: "gray",
        }}
      >
        {props.placeholder}
      </InputLabel>
      <Select
        fullWidth
        labelId="demo-select-small-label"
        id="demo-select-small"
        variant="outlined"
        formik={formik}
        size="medium"
        value={getIn(formik?.values, name)}
        defaultValue={defaultValue}
        onChange={formik?.handleChange}
        onBlur={formik?.handleBlur}
        error={getIn(formik?.touched, name) && !!getIn(formik?.errors, name)}
        {...props}
      >
        {type === "normal" &&
          options?.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option.name}
            </MenuItem>
          ))}

        {type === "country" && (
          <Box>
            <MenuItem value={options[0]?.name}>
              <Box>
                <img
                  src={options[0]?.flag_image_url}
                  alt={options[0]?.name}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                {options[0]?.name}
              </Box>
            </MenuItem>
            {options?.map((option, index) => (
              <MenuItem key={index} value={option?.name}>
                <Box>
                  <img
                    src={option?.flag_image_url}
                    alt={option?.name}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  {option?.name}
                </Box>
              </MenuItem>
            ))}
          </Box>
        )}
      </Select>
    </FormControl>
  );
}

export default FormSelectInput;

FormSelectInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(["normal", "country"]),
  formik: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      flag_image_url: PropTypes.string,
    })
  ),
  defaultValue: PropTypes.shape({
    name: PropTypes.string,
    flag_image_url: PropTypes.string,
  }),
};
