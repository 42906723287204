import * as React from "react";
import { Container, Grid, Link, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useUI from "../hooks/useUI";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    bottom: 0,
    left: 0,
    width: "100%",
    paddingBottom: theme.spacing(1),
    textAlign: "center",
  },
}));

const Footer = () => {
  const { isMobileMode } = useUI();
  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <>
      <Grid
        className={classes.root}
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
        }}
      >
        <Container maxWidth="md">
          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: isMobileMode ? "column": "row" }}>
            <Typography variant="t5" sx={{ mr: 1, fontSize: isMobileMode ? "12px": "14px" }}>
              ©2012 - {year} all rights reserved
            </Typography>
            <Typography>
              <Link
                underline="none"
                href={"https://enkap.cm/politique-de-confidentialite/"}
              >
                Privacy and Terms
              </Link>
            </Typography>
          </Box>
        </Container>
      </Grid>
    </>
  );
};
export default Footer;
