import React, { useContext, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { GlobalContext } from "../../context/GlobalContext";
import PoweredBy from "../../assets/images/poweredBy.svg";
import { ReactComponent as Logo } from "../../assets/images/logoMobile.svg";
import palette from "../../styles/palette";

const HeaderMobile = () => {
    const classes = useStyles();
    const { changeLanguage, language, theme, logo } = useContext(GlobalContext);
    const [isActive, setIsActive] = useState(language);

    return (
        <Grid container className={classes.root} sx={{ backgroundColor: theme?.headerColor ? theme.headerColor : palette.background.light }}>
            <Grid className={classes.container} item xs={12}>
            {logo ? <img className={classes.logo} src={`data:image/png;base64,${logo}`} alt="Merchant Logo" />: <Logo className={classes.logo} />}

                <Box item className={classes.rightPart}>
                    <img src={PoweredBy} className={classes.poweredBy} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default HeaderMobile;

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0 auto",
        height: 85,
        justifyContent: "center",
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: "100%",
        backgroundColor: theme.palette.background.light,
    },
    container: {
        display: "flex",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "center",
    },
    logo: {
        height: "100%",
        width: 137,
    },
    rightPart: {
        display: "flex",
        flexDirection: "column",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
    },
    button: {
        fontSize: "0.875rem !important",
        minWidth: "1rem !important",
        height: 24,
    },
    poweredBy: {
        width: 95,
        height: "100%",
    },
}));
