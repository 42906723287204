export const countryList = [
    {
      name: 'Algeria',
      code: 'DZ',
      flag: '🇩🇿',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/dz.png',
      phone_code: '+213',
      value: 'Algeria',
      key: '1'
    },
    {
      name: 'Angola',
      code: 'AO',
      flag: '🇦🇴',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ao.png',
      phone_code: '+244',
      value: 'Angola',
      key: '2'
    },
    {
      name: 'Benin',
      code: 'BJ',
      flag: '🇧🇯',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/bj.png',
      phone_code: '+229',
      value: 'Benin',
      key: '3'
    },
    {
      name: 'Botswana',
      code: 'BW',
      flag: '🇧🇼',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/bw.png',
      phone_code: '+267',
      value: 'Botswana',
      key: '4'
    },
    {
      name: 'Burkina Faso',
      code: 'BF',
      flag: '🇧🇫',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/bf.png',
      phone_code: '+226',
      value: 'Burkina Faso',
      key: '5'
    },
    {
      name: 'Burundi',
      code: 'BI',
      flag: '🇧🇮',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/bi.png',
      phone_code: '+257',
      value: 'Burundi',
      key: '6'
    },
    {
      name: 'Cabo Verde',
      code: 'CV',
      flag: '🇨🇻',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/cv.png',
      phone_code: '+238',
      value: 'Cabo Verde',
      key: '7'
    },
    {
      name: 'Cameroon',
      code: 'CM',
      flag: '🇨🇲',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/cm.png',
      phone_code: '+237',
      value: 'Cameroon',
      key: '8'
    },
    {
      name: 'Central African Republic',
      code: 'CF',
      flag: '🇨🇫',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/cf.png',
      phone_code: '+236',
      value: 'Central African Republic',
      key: '9'
    },
    {
      name: 'Chad',
      code: 'TD',
      flag: '🇹🇩',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/td.png',
      phone_code: '+235',
      value: 'Chad',
      key: '10'
    },
    {
      name: 'Comoros',
      code: 'KM',
      flag: '🇰🇲',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/km.png',
      phone_code: '+269',
      value: 'Comoros',
      key: '11'
    },
    {
      name: 'Democratic Republic of the Congo',
      code: 'CD',
      flag: '🇨🇩',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/cd.png',
      phone_code: '+243',
      value: 'Democratic Republic of the Congo',
      key: '12'
    },
    {
      name: 'Djibouti',
      code: 'DJ',
      flag: '🇩🇯',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/dj.png',
      phone_code: '+253',
      value: 'Djibouti',
      key: '13'
    },
    {
      name: 'Egypt',
      code: 'EG',
      flag: '🇪🇬',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/eg.png',
      phone_code: '+20',
      value: 'Egypt',
      key: '14'
    },
    {
      name: 'Equatorial Guinea',
      code: 'GQ',
      flag: '🇬🇶',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/gq.png',
      phone_code: '+240',
      value: 'Equatorial Guinea',
      key: '15'
    },
    {
      name: 'Eritrea',
      code: 'ER',
      flag: '🇪🇷',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/er.png',
      phone_code: '+291',
      value: 'Eritrea',
      key: '16'
    },
    {
      name: 'Eswatini',
      code: 'SZ',
      flag: '🇸🇿',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/sz.png',
      phone_code: '+268',
      value: 'Eswatini',
      key: '17'
    },
    {
      name: 'Ethiopia',
      code: 'ET',
      flag: '🇪🇹',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/et.png',
      phone_code: '+251',
      value: 'Ethiopia',
      key: '18'
    },
    {
      name: 'Gabon',
      code: 'GA',
      flag: '🇬🇦',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ga.png',
      phone_code: '+241',
      value: 'Gabon',
      key: '19'
    },
    {
      name: 'Gambia',
      code: 'GM',
      flag: '🇬🇲',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/gm.png',
      phone_code: '+220',
      value: 'Gambia',
      key: '20'
    },
    {
      name: 'Ghana',
      code: 'GH',
      flag: '🇬🇭',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/gh.png',
      phone_code: '+233',
      value: 'Ghana',
      key: '21'
    },
    {
      name: 'Guinea',
      code: 'GN',
      flag: '🇬🇳',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/gn.png',
      phone_code: '+224',
      value: 'Guinea',
      key: '22'
    },
    {
      name: 'Guinea-Bissau',
      code: 'GW',
      flag: '🇬🇼',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/gw.png',
      phone_code: '+245',
      value: 'Guinea-Bissau',
      key: '23'
    },
    {
      name: 'Ivory Coast',
      code: 'CI',
      flag: '🇨🇮',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ci.png',
      phone_code: '+225',
      value: 'Ivory Coast',
      key: '24'
    },
    {
      name: 'Kenya',
      code: 'KE',
      flag: '🇰🇪',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ke.png',
      phone_code: '+254',
      value: 'Kenya',
      key: '25'
    },
    {
      name: 'Lesotho',
      code: 'LS',
      flag: '🇱🇸',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ls.png',
      phone_code: '+266',
      value: 'Lesotho',
      key: '26'
    },
    {
      name: 'Liberia',
      code: 'LR',
      flag: '🇱🇷',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/lr.png',
      phone_code: '+231',
      value: 'Liberia',
      key: '27'
    },
    {
      name: 'Libya',
      code: 'LY',
      flag: '🇱🇾',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ly.png',
      phone_code: '+218',
      value: 'Libya',
      key: '28'
    },
    {
      name: 'Madagascar',
      code: 'MG',
      flag: '🇲🇬',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/mg.png',
      phone_code: '+261',
      value: 'Madagascar',
      key: '29'
    },
    {
      name: 'Malawi',
      code: 'MW',
      flag: '🇲🇼',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/mw.png',
      phone_code: '+265',
      value: 'Malawi',
      key: '30'
    },
    {
      name: 'Mali',
      code: 'ML',
      flag: '🇲🇱',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ml.png',
      phone_code: '+223',
      value: 'Mali',
      key: '31'
    },
    {
      name: 'Mauritania',
      code: 'MR',
      flag: '🇲🇷',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/mr.png',
      phone_code: '+222',
      value: 'Mauritania',
      key: '32'
    },
    {
      name: 'Mauritius',
      code: 'MU',
      flag: '🇲🇺',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/mu.png',
      phone_code: '+230',
      value: 'Mauritius',
      key: '33'
    },
    {
      name: 'Morocco',
      code: 'MA',
      flag: '🇲🇦',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ma.png',
      phone_code: '+212',
      value: 'Morocco',
      key: '34'
    },
    {
      name: 'Mozambique',
      code: 'MZ',
      flag: '🇲🇿',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/mz.png',
      phone_code: '+258',
      value: 'Mozambique',
      key: '35'
    },
    {
      name: 'Namibia',
      code: 'NA',
      flag: '🇳🇦',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/na.png',
      phone_code: '+264',
      value: 'Namibia',
      key: '36'
    },
    {
      name: 'Niger',
      code: 'NE',
      flag: '🇳🇪',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ne.png',
      phone_code: '+227',
      value: 'Niger',
      key: '37'
    },
    {
      name: 'Nigeria',
      code: 'NG',
      flag: '🇳🇬',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ng.png',
      phone_code: '+234',
      value: 'Nigeria',
      key: '38'
    },
    {
      name: 'Republic of the Congo',
      code: 'CG',
      flag: '🇨🇬',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/cg.png',
      phone_code: '+242',
      value: 'Republic of the Congo',
      key: '39'
    },
    {
      name: 'Rwanda',
      code: 'RW',
      flag: '🇷🇼',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/rw.png',
      phone_code: '+250',
      value: 'Rwanda',
      key: '40'
    },
    {
      name: 'Sao Tome and Principe',
      code: 'ST',
      flag: '🇸🇹',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/st.png',
      phone_code: '+239',
      value: 'Sao Tome and Principe',
      key: '41'
    },
    {
      name: 'Senegal',
      code: 'SN',
      flag: '🇸🇳',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/sn.png',
      phone_code: '+221',
      value: 'Senegal',
      key: '42'
    },
    {
      name: 'Seychelles',
      code: 'SC',
      flag: '🇸🇨',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/sc.png',
      phone_code: '+248',
      value: 'Seychelles',
      key: '43'
    },
    {
      name: 'Sierra Leone',
      code: 'SL',
      flag: '🇸🇱',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/sl.png',
      phone_code: '+232',
      value: 'Sierra Leone',
      key: '44'
    },
    {
      name: 'Somalia',
      code: 'SO',
      flag: '🇸🇴',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/so.png',
      phone_code: '+252',
      value: 'Somalia',
      key: '45'
    },
    {
      name: 'South Africa',
      code: 'ZA',
      flag: '🇿🇦',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/za.png',
      phone_code: '+27',
      value: 'South Africa',
      key: '46'
    },
    {
      name: 'South Sudan',
      code: 'SS',
      flag: '🇸🇸',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ss.png',
      phone_code: '+211',
      value: 'South Sudan',
      key: '47'
    },
    {
      name: 'Sudan',
      code: 'SD',
      flag: '🇸🇩',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/sd.png',
      phone_code: '+249',
      value: 'Sudan',
      key: '48'
    },
    {
      name: 'Tanzania',
      code: 'TZ',
      flag: '🇹🇿',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/tz.png',
      phone_code: '+255',
      value: 'Tanzania',
      key: '49'
    },
    {
      name: 'Togo',
      code: 'TG',
      flag: '🇹🇬',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/tg.png',
      phone_code: '+228',
      value: 'Togo',
      key: '50'
    },
    {
      name: 'Tunisia',
      code: 'TN',
      flag: '🇹🇳',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/tn.png',
      phone_code: '+216',
      value: 'Tunisia',
      key: '51'
    },
    {
      name: 'Uganda',
      code: 'UG',
      flag: '🇺🇬',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/ug.png',
      phone_code: '+256',
      value: 'Uganda',
      key: '52'
    },
    {
      name: 'Zambia',
      code: 'ZM',
      flag: '🇿🇲',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/zm.png',
      phone_code: '+260',
      value: 'Zambia',
      key: '53'
    },
    {
      name: 'Zimbabwe',
      code: 'ZW',
      flag: '🇿🇼',
      flag_image_url: 'https://flagpedia.net/data/flags/normal/zw.png',
      phone_code: '+263',
      value: 'Zimbabwe',
      key: '54'
    }
  ]