import axios from "axios";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import * as yup from "yup";

import { PAYPAL } from "../../../constants/data";
import { GlobalContext } from "../../../context/GlobalContext";
import useTranslation from "../../../customHooks/translations";
import useUI from "../../../hooks/useUI";
import FormDetailsDesktop from "./FormDetailsDesktop";
import FormDetailsMobile from "./FormDetailsMobile";

const FormDetails = ({ payment, order, merchant }) => {
    const { isMobileMode } = useUI();
    const { setErrorRequest, setPaymentProviderName, paymentProviderName, theme, searchResults } =
        useContext(GlobalContext);
    const translation = useTranslation();
    const navigate = useNavigate();
    const [initFormData, setInitFormData] = useState({});
    const [checked, setChecked] = useState(false);
    const [pricingId, setPricingId] = useState({});
    const [paymentProviderId, setPaymentProviderId] = useState({});
    const [radioChecked, setRadioChecked] = useState(false);
    const [providerType, setProviderType] = useState("");

    const FORM_VALIDATION = yup.object({
        notification: yup
            .object()
            .when("paymentProviderId.uuid", {
                // mtn
                is: "u123a1b8490a4ea1335eed85a799a6fb",
                then: (schema) => {
                    return yup.object({
                        customerName: yup
                            .string()
                            .required(`${translation.paymentNameError}`),
                        mobileNumber: yup
                            .string()
                            .required(`${translation.paymentPhoneError}`)
                            .matches(
                                /^(237|00237|\+237)?((650|651|652|653|654|680|681|682|683|684)[0-9]{6}$|(67[0-9]{7})$)/,
                                `${translation.wrongMobileNumber}`
                            ),
                    });
                },
            })
            .when("paymentProviderId.uuid", {
                // orange
                is: "b166771794fa2b1099212d5ff107e22a",
                then: (schema) => {
                    return yup.object({
                        customerName: yup
                            .string()
                            .required(`${translation.paymentNameError}`),
                        mobileNumber: yup
                            .string()
                            .required(`${translation.paymentPhoneError}`)
                            .matches(
                                /^(237)?((655|656|657|658|659|686|687|688|689)[0-9]{6}$|(69[0-9]{7})$)/,
                                `${translation.wrongMobileNumber}`
                            ),
                    });
                },
            })
            .when("paymentProviderId.uuid", {
                // yoomee
                is: "o911y2q8hg0a4ea19o52ed15h552a6hq",
                then: (schema) => {
                    return yup.object({
                        customerName: yup
                            .string()
                            .required(`${translation.paymentNameError}`),
                        mobileNumber: yup
                            .string()
                            .required(`${translation.paymentPhoneError}`)
                            .matches(
                                /^(237)?((24)[0-9]{7}$)/,
                                `${translation.wrongMobileNumber}`
                            ),
                    });
                },
            })
            .when("paymentProviderId.uuid", {
                // EXPRESS UNION
                is: "n123m1b8490a4ea1335eed85b799a6fb",
                then: (schema) => {
                    return yup.object({
                        customerName: yup
                            .string()
                            .required(`${translation.paymentNameError}`),
                        mobileNumber: yup
                            .string()
                            .required(`${translation.paymentPhoneError}`)
                            .matches(
                                /^\+?[1-9]\d{1,14}$/,
                                `${translation.wrongMobileNumber}`
                            ),
                    });
                },
            }),
        providerType: yup
            .string()
            .required(`${translation.paymentMethodError}`)
            ,
        paymentProviderId: yup
            .object({
                uuid: yup.string(),
            })
            .required(`${translation.paymentMethodError}`)
            ,

        walletId:
            paymentProviderName === "safimoney" &&
            yup
                .string()
                .nullable()
                .min(16, `${translation.walletIdLength}`)
                .max(16, `${translation.walletIdLength}`)
                .required(`${translation.walletIdError}`),
    });

    useEffect(() => {
        let INITIAL_FORM_STATE = {
            id: order?.id ? order?.id : "",
            notification: {
                customerName: order?.customerName ? order?.customerName : "",
                email: order?.email ? order?.email : "",
                mobileNumber: order?.phoneNumber
                    ? order?.phoneNumber.length <= 9
                        ? `+237${order?.phoneNumber}`
                        : order?.phoneNumber
                    : "+237",
            },
            providerType: providerType ? providerType : null,
            paymentProviderId: paymentProviderId ? paymentProviderId : {},
            pricingId: pricingId ? pricingId : "",
            totalAmount: order?.amountPaid || null,
            merchantId: merchant?.id ? merchant?.id : null,
            otp: null,
            walletId: null,
        };
        setInitFormData(INITIAL_FORM_STATE);
    }, [providerType, payment, order, pricingId, paymentProviderId, merchant?.id]);

    const handleChecked = (event) => {
        setChecked(event.target.checked);
    };
    const getPaypalUrl = (values) => `/payment/api/order/paypal/${searchResults?.orderPlacement?.id?.uuid}/init/${values?.paymentProviderId?.uuid}`;
    const getMobileUrl = (values) => `/payment/api/order/${values?.id?.uuid}/payWith/${values?.paymentProviderId?.uuid}`;

    const isPaypal = providerType.toLowerCase() === "paypal";
    const handleFormSubmit = (values) => {
        console.log("Values: ", values)
        axios.defaults.withCredentials = true;
        axios({
            method: isPaypal ? "GET" : "PUT",
            baseURL: window._env_.REACT_APP_API_HOST,
            url: isPaypal ? getPaypalUrl(values) : getMobileUrl(values),
            credentials: "include",
            withCredentials: true,
            data: values,
        })
            .then((resp) => {
                // if payment method is different from paypal, use normal flow
                if (resp.status === 200 && !isPaypal) {
                  return navigate(`/thanks-page`);
                }

                window.location.replace(resp.data)
            })
            .catch((error) => {
                let resp = error?.response?.data;
                setErrorRequest({
                    code: resp?.errorCodeNumeric,
                    message: resp?.message,
                });
                setTimeout(() => {
                    navigate(`/error-page`);
                }, 1000);
            });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { ...initFormData },
        validateOnMount: true,
        validationSchema: FORM_VALIDATION,
        onSubmit: handleFormSubmit,
    });

    return isMobileMode ? (
        <FormDetailsMobile
            theme={theme}
            formik={formik}
            payment={payment}
            setPaymentProviderId={setPaymentProviderId}
            setPricingId={setPricingId}
            setPaymentProviderName={setPaymentProviderName}
            radioChecked={radioChecked}
            setRadioChecked={setRadioChecked}
            paymentProviderName={paymentProviderName}
            handleChecked={handleChecked}
            checked={checked}
            providerType={providerType}
            setProviderType={setProviderType}
            searchResults={searchResults}
        />
    ) : (
        <FormDetailsDesktop
            theme={theme}
            formik={formik}
            payment={payment}
            setPaymentProviderId={setPaymentProviderId}
            setPricingId={setPricingId}
            setPaymentProviderName={setPaymentProviderName}
            radioChecked={radioChecked}
            setRadioChecked={setRadioChecked}
            paymentProviderName={paymentProviderName}
            checked={checked}
            handleChecked={handleChecked}
            providerType={providerType}
            setProviderType={setProviderType}
            searchResults={searchResults}
        />
    );
};

export default FormDetails;
