import React from 'react';
import {makeStyles} from "@mui/styles";
import {Grid} from "@mui/material"

const useStyles = makeStyles(theme => ({
    root: {
        margin: "0 auto",
        justifyContent: "center",
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        width: "100%",
    },

}));
const GridWrapper = ({children, direction,alignItems}) => {
    const classes = useStyles();
    return (
        <Grid container direction={direction} className={classes.root} alignItems={alignItems}>
            {children}
        </Grid>
    );
};

export default GridWrapper;