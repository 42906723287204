import React from "react";
import {Typography} from "@mui/material";

export default [
    {
        id: 'number',
        label: <React.Fragment>
            <Typography variant='b8' align='right'>N°</Typography>
        </React.Fragment>,
        key: 'number',
        type: 'string',
        align: "left",
    },
    {
        id: 'description',
        label: <React.Fragment>
            <Typography variant='b8' align='right'>Déscription</Typography>
            <Typography variant='t4' component='p' color="#2E2E2E">Description<span> </span></Typography>
        </React.Fragment>,
        key: 'description',
        type: 'string',
        align: "left",
    },
    {
        id: 'qty',
        label: <React.Fragment>
            <Typography variant='b8' align='right'>Qté</Typography>
            <Typography variant='t4' component='p' color="#2E2E2E">Qty<span> </span></Typography>
        </React.Fragment>,
        key: 'qty',
        type: 'string',
        align: "center",
    },
    {
        id: 'itemPrice',
        label: <React.Fragment>
            <Typography variant='b8' align='right'>Prix/ FCFA</Typography>
            <Typography variant='t4' component='p' color="#2E2E2E">price<span> </span></Typography>
        </React.Fragment>,
        key: 'itemPrice',
        type: 'string',
        align: "center",
    },
    {
        id: 'amount',
        label: <React.Fragment>
            <Typography variant='b8' align="right">Montant/ FCFA</Typography>
            <Typography variant='t4' component='p' color="#2E2E2E">Amount</Typography>
        </React.Fragment>,
        key: 'amount',
        type: 'string',
        align: "center",
    },
];
