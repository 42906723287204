import { grey } from "@mui/material/colors";

export default {
    red: "#ff0000",
    orange: "#f39410",
    green: "#6daf57",
    darkBlue: "#303668",
    lightBlue: "#0d92dd",
    black: "#2E2E2E",
    text: {
        primary: "#000000",
        secondary: "#4784F9",
    },
    border: {
        default: "#B8B7B7",
        gray: "#8c8c8b",
        lightGray: "#EAECF0",
    },
    background: {
        default: "#ffffff",
        light: "#F5F5F5",
        grey: "#F2F2F2"
    },
    primary: {
        main: "#0AA5B7",
    },
    secondary: {
        main: "#c0d869",
        dark: "#6daf57",
        contrastText: "#ffffff",
    },
    grey: {
        ...grey,
        350: "#c4c4c4",
        750: "#545c64",
        100: "#f6f6f6",
        med: "#dcdddd",
    },
};
