import React, { useContext } from "react";
import useUI from "../../../hooks/useUI";
import OrdersCardMobile from "./OrdersCardMobile";
import OrdersCardDesktop from "./OrdersCardDesktop";
import { GlobalContext } from "../../../context/GlobalContext";

const OrdersCard = ({ data }) => {
    const { isMobileMode } = useUI();
    const { theme } =
    useContext(GlobalContext);
    const filteredItems = data?.items
        ? data.items.filter((item) => item.particulars !== "Service Charges")
        : [];

    const amountsData = [
        {
            name: "Montant / Amount",
            value:
                data?.totalAmount && data?.currency
                    ? `${data.totalAmount
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                          data.currency === "XAF" ? "FCFA" : data.currency
                      }`
                    : null,
        },
        {
            name: "Frais de service / Service charge",
            value:
                data?.appliedServiceCharge && data?.currency
                    ? `${data.appliedServiceCharge
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                          data.currency === "XAF" ? "FCFA" : data.currency
                      }`
                    : null,
        },
        {
            name: "Montant Total / Total Amount",
            lastItem: true,
            value:
                data?.amountPaid && data?.currency
                    ? `${data.amountPaid
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                          data.currency === "XAF" ? "FCFA" : data.currency
                      }`
                    : null,
        },
    ];

    return isMobileMode ? (
        <OrdersCardMobile
            data={data}
            items={filteredItems}
            amountsData={amountsData}
            theme={theme}
        />
    ) : (
        <OrdersCardDesktop
            data={data}
            items={filteredItems}
            amountsData={amountsData}
            theme={theme}
        />
    );
};

export default OrdersCard;
