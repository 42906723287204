import React, { useContext } from "react";
import FormDetails from "./FormDetails";
import OrdersCard from "./OrdersCard";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { GlobalContext } from "../../context/GlobalContext";
import useUI from "../../hooks/useUI";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: "0 auto",
        [theme.breakpoints.down("md")]: {
            paddingRight: 0,
        },
    },
}));

const OrdersPage = () => {
    const classes = useStyles();
    const { searchResults, load } = useContext(GlobalContext);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("xs"));
    const { isMobileMode } = useUI();

    return (
        <>
            {!load ? (
                <Grid
                    container
                    item
                    justifyContent="space-between"
                    direction={matches ? "row" : "column"}
                    sx={{ flexBasis: isMobileMode ? "100%": "66.666667%" }}
                >
                    <Grid
                        item
                        xl={5}
                        xs={12}
                        lg={12}
                        className={classes.root}
                        order={{ xs: 2, sm: 2, xl: 1 }}
                    >
                        <FormDetails
                            payment={searchResults?.paymentProviderData}
                            order={searchResults?.orderPlacement}
                            merchant={searchResults?.merchantInfo}
                        />
                    </Grid>
                    <Grid
                        item
                        xl={6}
                        xs={12}
                        mb={isMobileMode ? 3 : 6}
                        lg={10}
                        className={classes.root}
                        order={{ xs: 1, sm: 1, xl: 2 }}
                    >
                        <OrdersCard data={searchResults?.orderPlacement} />
                    </Grid>
                </Grid>
            ) : (
                <Typography>Loading...</Typography>
            )}
        </>
    );
};

export default OrdersPage;
