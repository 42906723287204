import React, { useContext } from "react";
import { makeStyles } from "@mui/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import useTranslation from "../../customHooks/translations";
import palette from "../../styles/palette";
import {GlobalContext} from "../../context/GlobalContext";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(5),
        "& .MuiTableCell-root": {
            fontSize: "1rem",
            fontWeight: 400,
            textAlign: "left",
            border: `1px solid ${theme.palette.border.gray}`,
        },
        [theme.breakpoints.down("md")]: {
            marginBottom: theme.spacing(2),
        },
    },
    tableHead: {
        [theme.breakpoints.down("md")]: {
            height: 43,
            "& th:nth-child(2)": {
                paddingLeft: `${theme.spacing(1)} !important`,
            },
        },
    },
    tableHeader: {
        "& .MuiTableCell-root": {
            fontSize: "1rem",
            fontWeight: "bold",
            lineHeight: "1.5rem",
            textTransform: "capitalize",
            verticalAlign: "top",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
            [theme.breakpoints.down("md")]: {
                fontSize: "0.875rem",
                fontWeight: 500,
                paddingBottom: 0,
                paddingTop: 0,
                paddingLeft: theme.spacing(3),
                verticalAlign: "middle",
            },
        },
        "& th:nth-child(1)": {
            borderRight: `0 !important`
        },
        "& th:nth-child(2)": {
            borderLeft: `0 !important`
        },
    },
    provider: {
        [theme.breakpoints.down("md")]: {
            verticalAlign: "middle",
            paddingLeft: `${theme.spacing(3)} !important`,
            "& img": {
                width: 80,
                display: "block",
            },
        },
    },
    insructions: {
        [theme.breakpoints.down("md")]: {
            fontSize: "0.875rem !important",
            paddingTop: `${theme.spacing(1)} !important`,
            paddingBottom: `${theme.spacing(1)} !important`,
            paddingLeft: `${theme.spacing(1)} !important`,
            paddingRight: `${theme.spacing(3)} !important`,
        },
    },
}));

export default function ThanksPageGrid(props) {
    const { columns, rows } = props;
    const classes = useStyles();
    const translation = useTranslation();
    const {theme} = useContext(GlobalContext);

    return (
        <Table className={classes.root}>
            <TableHead className={classes.tableHead}>
                <TableRow className={classes.tableHeader}>
                    {columns.map((column) => (
                        <TableCell
                            className={classes.tableHeader}
                            style={{ backgroundColor: theme?.primaryColor ? theme.primaryColor : palette.primary.main }}
                            key={column.id}
                            align={column.align}
                        >
                            {translation[column.label]}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow key={row?.id}>
                        <TableCell
                            component="th"
                            scope="row"
                            className={classes.provider}
                        >
                            {row?.provider}
                        </TableCell>
                        <TableCell
                            align="right"
                            className={classes.insructions}
                        >
                            {translation[row.description]}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}
