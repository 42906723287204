import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingBottom: theme.spacing(2),
    },
  },
  subTitle1: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  subTitle2: {
    paddingBottom: theme.spacing(3.5),
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingBottom: theme.spacing(2),
    },
  },
  text: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      paddingBottom: theme.spacing(3),
    },
  },
  redirectMessage: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(1),
    },
  },
}));


export default useStyles