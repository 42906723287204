import palette from "./palette";

export default {
    useNextVariants: true,
    fontFamily: ["IBM Plex Sans", "sans-serif"].join(","),
    h1: {
        fontSize: "2.25rem",
        fontWeight: 300,
    },
    h2: {
        fontSize: "2rem",
    },
    body1: {
        fontSize: "0.875rem",
    },
    body2: {
        fontSize: "0.625rem",
    },
    h3: {
        fontSize: "1rem",
        fontWeight: "700",
    },
    c1: {
        fontSize: "0.375rem",
    },
    b1: {
        fontSize: "1.875rem",
        fontWeight: 700,
    },
    b2: {
        fontSize: "1.5rem",
    },
    b3: {
        fontSize: "1.25rem",
        fontWeight: 700,
    },
    b4: {
        fontSize: "1.25rem",
        fontWeight: 400,
    },
    b5: {
        fontSize: "1.25rem",
        fontWeight: 600,
    },
    b6: {
        fontSize: "1rem",
        fontWeight: 600,
    },
    b7: {
        fontSize: "0.875rem",
        fontWeight: 600,
    },
    b8: {
        fontSize: "1rem",
        fontWeight: 500,
    },
    t1: {
        fontSize: "1rem",
        fontWeight: "bold",
        lineHeight: "1.5rem",
        textTransform: "uppercase",
    },
    t2: {
        fontSize: "0.8rem",
        lineHeight: "1rem",
        textTransform: "uppercase",
    },
    t3: {
        fontSize: "1.125rem",
        lineHeight: "1.5rem",
    },
    t4: {
        fontSize: "0.875rem",
        fontWeight: 400,
    },
    t5: {
        fontSize: "1rem",
        fontWeight: 400,
    },
    t6: {
        fontSize: "0.625rem",
        fontWeight: 400,
    },
    t7: {
        fontSize: "0.75rem",
        fontWeight: 400,
    },
    subtitle: {
        fontSize: "1.25rem",
        fontWeight: 700,
    },
    overline: {
        color: palette.primary.light,
        fontSize: 12,
        fontWeight: "bold",
        letterSpacing: 0.4,
    },
    button: {
        fontSize: '0.875rem',
        fontWeight: "bold",
        color: "#FFFFFF",
    },
};
