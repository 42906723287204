import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { getIn } from "formik";
import React, { useEffect, useState } from "react";

import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import ButtonStyled from "../../../../components/ButtonStyled";
import FormAutoComplete from "../../../../components/FormAutoComplete";
import FormSelectInput from "../../../../components/FormSelectInput";
import FormTextField from "../../../../components/FormTextField";
import { PhoneNumberInput } from "../../../../components/PhoneNumberInput";
import { countryList } from "../../../../constants";
import useTranslation from "../../../../customHooks/translations";
import palette from "../../../../styles/palette";
import useStyles from "./styles";
import { MOBILE_MONEY, PAYPAL } from "../../../../constants/data";
import handleSelectChange from "../utils/handleSelectChange";
import paypalIcon from "../../../../assets/images/paypal.png";

const FormDetailsMobile = ({
  formik,
  payment,
  setPaymentProviderId,
  setPricingId,
  setPaymentProviderName,
  radioChecked,
  setRadioChecked,
  paymentProviderName,
  checked,
  handleChecked,
  theme,
  providerType,
  setProviderType,
}) => {
  const classes = useStyles();
  const translation = useTranslation();

  const isSubmitDisabled = formik.isSubmitting || !checked || !formik.isValid;
  const isPaypal = formik?.values?.providerType === PAYPAL;

  const [selectedOption, setSelectedOption] = useState(null);
  const defaultValue = countryList.find(
    (item) => item?.name === "Cameroon"
  )
  useEffect(() => {
    if (defaultValue && !selectedOption) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue, selectedOption]);

  return (
    <>
      <Grid
        className={classes.formHeader}
        sx={{
          backgroundColor: theme?.primaryColor
            ? theme.primaryColor
            : palette.primary.main,
        }}
        container
        direction="column"
      >
        <Typography variant="b6">Paiement</Typography>
        <Typography variant="b7" style={{ fontWeight: 500 }}>
          Payment
        </Typography>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction="column" className={classes.rootForm}>
          {/* Moyen de paiements */}
          <Grid item>
            <Typography
              variant="b7"
              className={classes.fieldTitle}
              gutterBottom
            >
              Moyen de paiement
              <span className={classes.marker}>*</span>
            </Typography>
            <Grid item>
              <FormControl
                className={classes.fieldset}
                component="fieldset"
                fullWidth
              >
                <Typography
                  component="legend"
                  variant="t6"
                  className={classes.fieldTitleSecondary}
                >
                  Payment method
                </Typography>

                <FormSelectInput
                  id="providerType"
                  name="providerType"
                  size="medium"
                  defaultValue={providerType}
                  formik={formik}
                  onBlur={formik?.handleBlur}
                  placeholder={translation.selectProvider}
                  onChange={(e) =>
                    handleSelectChange(e, {
                      formik,
                      setPaymentProviderId,
                      setPricingId,
                      setProviderType,
                    })
                  }
                  options={payment?.filter(
                    (paymentProvider) => paymentProvider.paymentOptions.length
                  )}
                />
              </FormControl>

              {
                <Typography
                  pb={4}
                  pl={2}
                  style={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                  }}
                >
                  {formik.errors.paymentProviderId?.uuid}
                </Typography>
              }
            </Grid>
          </Grid>
          {providerType && (
            <>
              {/* Country */}
              {formik?.values?.providerType === MOBILE_MONEY && (
                <Grid item>
                  <Typography
                    variant="b7"
                    className={classes.fieldTitle}
                    gutterBottom
                  >
                    Pays
                    <span className={classes.marker}>*</span>
                  </Typography>
                  <Grid item>
                    <FormControl
                      className={classes.fieldset}
                      component="fieldset"
                      error
                      fullWidth
                    >
                      <Typography
                        component="legend"
                        variant="t6"
                        className={classes.fieldTitleSecondary}
                      >
                        Country
                      </Typography>
                      <FormAutoComplete
                        fullWidth
                        source="country"
                        options={countryList}
                        defaultValue={defaultValue}
                        disableClearable
                        disabled={true}
                        variant={"outlined"}
                        type={"country"}
                        label={""}
                        onChange={(_, value) => setSelectedOption(value)}
                        selectedOption={selectedOption}
                        size="medium"
                      />
                    </FormControl>

                    {
                      <Typography
                        pb={4}
                        pl={2}
                        style={{
                          color: "#d32f2f",
                          fontSize: "0.75rem",
                        }}
                      >
                        {formik.errors.paymentProviderId?.uuid}
                      </Typography>
                    }
                  </Grid>
                </Grid>
              )}

              {/* Opérateur mobile money */}
              {!isPaypal && (
                <Grid item>
                  <Typography variant="b7" className={classes.fieldTitle}>
                    Opérateur mobile money
                    <span className={classes.marker}>*</span>
                  </Typography>
                  <Grid pb={3} item>
                    <FormControl
                      className={classes.fieldset}
                      component="fieldset"
                    >
                      <Typography
                        component="legend"
                        variant="t6"
                        className={classes.fieldTitleSecondary}
                      >
                        Mobile money Provider
                      </Typography>
                      {formik?.values?.providerType && payment && (
                        <Box className={classes.radioGroupContainer}>
                          {payment
                            ?.find((item) => item?.name === providerType)
                            ?.paymentOptions?.map(
                              (i) =>
                                i?.paymentProviderInfo?.enabled && (
                                  <RadioGroup
                                    key={i?.paymentProviderInfo?.id?.uuid}
                                    aria-required={true}
                                    className={classes.radioBlockWrapper}
                                    name="paymentProviderId"
                                    aria-label="payment"
                                    row
                                    onChange={() =>
                                      formik.setFieldValue(
                                        "paymentProviderId",
                                        i?.paymentProviderInfo?.id
                                      )
                                    }
                                    onBlur={formik?.handleBlur}
                                    error={
                                      getIn(
                                        formik?.touched,
                                        "paymentProviderId.uuid"
                                      ) &&
                                      !!getIn(
                                        formik?.errors,
                                        "paymentProviderId.uuid"
                                      )
                                    }
                                    helperText={
                                      getIn(
                                        formik?.touched,
                                        "paymentProviderId.uuid"
                                      ) &&
                                      getIn(
                                        formik?.errors,
                                        "paymentProviderId.uuid"
                                      )
                                    }
                                    value={getIn(
                                      formik?.values,
                                      "paymentProviderId"
                                    )}
                                  >
                                    <FormControlLabel
                                      id={i?.paymentProviderInfo?.id?.uuid}
                                      onClick={() => {
                                        setPaymentProviderId(
                                          i?.paymentProviderInfo?.id
                                        );
                                        formik.setFieldValue(
                                          "paymentProviderId",
                                          i?.paymentProviderInfo?.id
                                        );
                                        setPricingId(
                                          i?.paymentProviderInfo
                                            ?.customerPricingId
                                        );
                                        setPaymentProviderName(
                                          i?.paymentProviderInfo?.name
                                        );
                                      }}
                                      key={i?.paymentProviderInfo?.id?.uuid}
                                      value={i?.paymentProviderInfo?.id}
                                      control={
                                        <Radio
                                          name={i?.paymentProviderInfo?.name}
                                          checked={
                                            radioChecked ===
                                              i?.paymentProviderInfo?.name &&
                                            true
                                          }
                                          onClick={(e) =>
                                            setRadioChecked(e.target.name)
                                          }
                                          className={classes.radio}
                                        />
                                      }
                                      label={
                                        <img
                                          className={classes.providerImg}
                                          src={i?.paymentProviderInfo?.logoUrl}
                                          alt={i?.paymentProviderInfo?.name}
                                          loading="lazy"
                                        />
                                      }
                                    />
                                  </RadioGroup>
                                )
                            )}
                        </Box>
                      )}
                    </FormControl>
                    {formik.errors.paymentProviderId?.uuid && (
                      <Typography
                        pl={1}
                        variant="t6"
                        sx={{
                          color: "#d32f2f",
                        }}
                      >
                        {formik.errors.paymentProviderId?.uuid}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}

              {/* Phone number */}
              {!isPaypal && (
                <Grid item>
                  <Typography
                    variant="b7"
                    className={classes.fieldTitle}
                    gutterBottom
                  >
                    {paymentProviderName === "safimoney"
                      ? "ID de portefeuille"
                      : "Numéro mobile money"}
                    <span className={classes.marker}>*</span>
                  </Typography>
                  {paymentProviderName === "safimoney" ? (
                    <FormControl
                      sx={{
                        width: "100%",
                        "& .MuiFormControl-root": {
                          marginTop: "0 !important",
                        },
                        "& .MuiFormControlLabel-root": {
                          marginLeft: 0,
                        },
                      }}
                    >
                      <Typography
                        component="legend"
                        variant="t6"
                        className={classes.fieldTitleSecondary}
                      >
                        Wallet ID
                      </Typography>
                      <FormTextField
                        id="walletId"
                        name="walletId"
                        variant="outlined"
                        className={classes.input}
                        formik={formik}
                      />
                    </FormControl>
                  ) : (
                    <FormControl className={classes.pnoheNumberFormControl}>
                      <Typography
                        component="legend"
                        variant="t6"
                        className={classes.fieldTitleSecondary}
                      >
                        Mobile money number
                      </Typography>
                      <PhoneNumberInput
                        fullWidth
                        id="notification.mobileNumber"
                        name="notification.mobileNumber"
                        variant="outlined"
                        formik={formik}
                        disabled={!radioChecked}
                      />
                    </FormControl>
                  )}
                </Grid>
              )}

              {/* Name and Surname */}
              {!isPaypal && (
                <Grid item xl={10} md={12} mt={2}>
                  <Typography variant="b7" className={classes.fieldTitle}>
                    Nom et prénom
                    <span className={classes.marker}>*</span>
                  </Typography>
                  <FormControl fullWidth>
                    <Typography
                      component="legend"
                      variant="t6"
                      className={classes.fieldTitleSecondary}
                    >
                      Name and Surname
                    </Typography>
                    <Grid item className={classes.inputWrapper}>
                      <FormTextField
                        fullWidth
                        id="notification.customerName"
                        name="notification.customerName"
                        variant="outlined"
                        formik={formik}
                        className={classes.input}
                      />
                    </Grid>
                  </FormControl>
                </Grid>
              )}

              {/* Submit Button */}
              <Grid item className={classes.buttonContainer}>
                {isPaypal && (
                  <Typography
                    style={{
                      fontSize: "0.75rem",
                    }}
                    mb={1}
                  >
                    {translation.paypalRedirection}
                  </Typography>
                )}

                <ButtonStyled
                  disabled={isSubmitDisabled}
                  type="submit"
                  label={isPaypal ? "" : translation.paymentButton}
                  icon={isPaypal && <img src={paypalIcon} alt="paymentIcon" />}
                  style={{
                    backgroundColor: isSubmitDisabled
                      ? theme?.primaryColor
                      : theme?.secondaryColor
                      ? theme.secondaryColor
                      : isPaypal
                      ? palette.orange
                      : palette.primary.main,
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid className={classes.checkBoxWrapper} container direction="column">
          {!checked && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                color: "#d32f2f",
              }}
            >
              <WarningAmberRoundedIcon sx={{ marginRight: 1, fontSize: 20 }} />
              <Typography
                variant="t6"
                sx={{
                  color: "#d32f2f",
                }}
              >
                {translation.paymentTerms}
              </Typography>
            </Box>
          )}
          <FormControlLabel
            className={classes.checkBox}
            label={terms}
            control={
              <Checkbox
                checked={checked}
                onChange={handleChecked}
                sx={{
                  "&.Mui-checked": {
                    color: theme?.primaryColor
                      ? theme?.primaryColor
                      : palette.primary.main,
                  },
                }}
              />
            }
          />
        </Grid>
      </form>
    </>
  );
};

export default FormDetailsMobile;

const terms = (
  <>
    <Typography>
      J’accepte les
      <Link
        underline="none"
        color="#4784F9"
        href="https://enkap.cm/politique-de-confidentialite/"
      >
        {" "}
        termes et les conditions
      </Link>
    </Typography>
    <Typography>
      I agree to the
      <Link
        underline="none"
        color="#4784F9"
        href="https://enkap.cm/privacy-policy/?lang=en"
      >
        {" "}
        terms and conditions
      </Link>
    </Typography>
  </>
);
