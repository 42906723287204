import React from 'react';
import {makeStyles} from "@mui/styles";
import {Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import { trimText } from '../../helpers/trimText';

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTableCell-root": {
            fontSize: '1rem',
            borderBottom: `1px solid ${theme.palette.border.lightGray}`,
            width: 'fit-content',
            paddingLeft: 0,
            paddingRight: 0,
            marginRight: theme.spacing(3),
            marginLeft: theme.spacing(3)
        }
    },
    row: {
        "& .MuiTableRow-root": {
            paddingTop: theme.spacing(2.5),
            paddingBottom: theme.spacing(1.5),
            marginRight: theme.spacing(3),
            marginLeft: theme.spacing(3),
        },
    },
    tableHeader: {
        "& .MuiTableCell-root": {
            fontSize: '1rem',
            fontWeight: 'bold',
            lineHeight: '1.5rem',
            verticalAlign: 'top',
            width: 100
        },
        "& th:nth-child(1), & th:nth-child(3)": {
            width: `30px !important`,
            maxWidth: `30px !important`
        },
    },
}));


export default function CardGrid({columns, rows, currency}) {

    const classes = useStyles()


    return (

        <>
            <Table className={classes.root}>
                <TableHead>
                    <TableRow className={classes.tableHeader}>
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        rows?.map((row, index) => (
                            <TableRow
                                className={classes.row}
                                key={row?.itemId}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell align="left">
                                    <Tooltip title={row?.particulars}>
                                        {trimText(row?.particulars)}
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="center" style={{ textAlign: "center"}}>{row?.quantity}</TableCell>
                                <TableCell align="center">{row?.unitCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} </TableCell>
                                <TableCell
                                    align="center">{row?.subTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} </TableCell>
                            </TableRow>

                        ))}
                </TableBody>
            </Table>
        </>)
}


