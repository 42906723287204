import React from 'react'
import {TextField} from "@mui/material";
import {getIn} from 'formik';

const FormTextField = ({name, label, formik, defaultValue, multiline, ...props}) => {

    return (
        <TextField
            fullWidth
            multiline={multiline}
            id={name}
            name={name}
            margin="dense"
            variant="outlined"
            InputLabelProps={{
                shrink: true,
            }}
            defaultValue={defaultValue}
            value={getIn(formik?.values, name)}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            error={getIn(formik?.touched, name) && !!getIn(formik?.errors, name)}
            helperText={getIn(formik?.touched, name) && getIn(formik?.errors, name)}
            {...props}
        />
    );
};

export default FormTextField;
