import React from "react";
import useUI from "../../hooks/useUI";
import HeaderMobile from "./HeaderMobile";
import HeaderDesktop from "./HeaderDesktop";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Alert } from "../../components/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginTop: "30px",
      }
}))

const Header = () => {
  const { isMobileMode } = useUI();
  const { pathname } = useLocation();

  const showAlert = pathname === "/";

  const classes = useStyles();

  return (
    <>
      {isMobileMode ? <HeaderMobile /> : <HeaderDesktop />}
      {showAlert && (
        <Box
          className={classes.container}
        >
          <Box style={{ width: isMobileMode ? "92%": "65.666667%", marginBottom: 5 }}>
            <Alert
              severity="info"
              style={{ fontSize: "16px" }}
              titleProps={{ style: { fontWeight: 400, marginTop: 0 } }}
              variant="standard"
              title="Avant  de continuer le processus de paiement, nous vous invitions a verifier que vous disposez bien du montant de la transaction sur vos compte"
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Header;
