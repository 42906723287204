const handleSelectChange = (
  e,
  { formik, setPaymentProviderId, setPricingId, setProviderType }
) => {
  const paymentProvider = e.target.value;
  formik.setFieldValue("providerType", paymentProvider.name);
  setProviderType(paymentProvider.name);
  if (e.target.value.name.toLowerCase().includes("paypal")) {
    const paymentProviderInfo =
      paymentProvider.paymentOptions[0].paymentProviderInfo;

    formik.setFieldValue("paymentProviderId", {
      uuid: paymentProviderInfo.id.uuid,
    });
    setPaymentProviderId({ uuid: paymentProviderInfo.id.uuid });
    formik.setFieldValue("customerPricingId", {
      uuid: paymentProviderInfo.customerPricingId.uuid,
    });
    setPricingId(paymentProviderInfo.customerPricingId.uuid);
  }
};
export default handleSelectChange;
