import React from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useUI from "../hooks/useUI";

const Error401Page = () => {
    const classes = useStyles();
    const todaysDate = new Date();
    const todaysDateISO = todaysDate.toLocaleString();
    const { isMobileMode } = useUI();

    return (
        <Grid container item xl={9}>
            <Grid item xs={12} xl={10} md={8}>
                <Typography
                    variant={isMobileMode ? "b5" : "b1"}
                    component="p"
                    className={classes.title}
                >
                    Support Friendly Error Page
                </Typography>
                <Typography
                    variant={isMobileMode ? "b6" : "subtitle"}
                    component="p"
                    className={classes.time}
                >
                    <strong>Occurred:</strong> {todaysDateISO}
                </Typography>
                <Typography
                    variant={isMobileMode ? "t4" : "t5"}
                    component="p"
                    className={classes.status}
                >
                    Response Status: 401 (Unauthorized)
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Error401Page;

const useStyles = makeStyles((theme) => ({
    title: {
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingBottom: theme.spacing(1.5),
        },
    },
    time: {
        paddingBottom: theme.spacing(5.5),
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingBottom: theme.spacing(1.5),
        },
    },
    status: {
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
}));
