export default {
    paymentButton: "SUBMIT",
    paymentPhoneError: "Your mobile number is required",
    paymentNameError: "Your name is required",
    paymentMethodError: "Payment option is required",
    paymentTerms: "Your agreement with terms terms and condition is required",
    thanksPageTitle1: "Thank you for choosing",
    thanksPageTitle2: "for your payment !",
    providerTitle: "Provider",
    instructionsTitle: "Instructions",
    thanksPageSubTitle1: "Your payment is being processed.",
    thanksPageSubTitle2: "You shall be notified once it has been completed.",
    thanksPageText:
        "If you paid via Mobile Money, there is a small chance that you may not receive the prompt from your Mobile Money Provider. In that case, you can manually \n" +
        "trigger the payment.",
    orangeInstructions:
        "Dial #150*50# to validate your withdrawal \n" +
        "in case you did not receive prompt.",
    mtnInstructions:
        "Dial *126# to validate your withdrawal in case you did not receive prompt.",
    euInstructions:
        "Just login in your Express Union Mobile App click on Authorize \n" +
        "and approve the withdrawal in case you did not receive SMS notification from Express Union.",
    yoomeeInstructions:
        "Login into your Yoomee Money Mobile App, Go to Cashout click on Incoming Cashout Request and Accept the withdrawal",
    backToShopButton: "Back to",
    backToMerchantSite: "Back to merchant site",
    successfulTransaction: "Successful transaction!",
    wasSuccessfullyTreated: "was successfully treated.",
    transactionFailed: "Transaction failed!",
    hasFailed: "has failed.",
    yourPaymentId: "Your payment",
    redirectMessage1: "You will be redirected in",
    redirectMessage2: " seconds to merchant’s site.",
    errorPageTitle: "Error.",
    errorPageSubtitle: "An error occurred during the corresponding request.",
    mobileError: "Please provide a valid mobile money number.",
    paymentError:
        "Cannot process payment: Select a payment method and provide a mobile number.",
    generalError: "General error during transaction processing.",
    contactSupport: "Contact support team ",
    backButton: "Back",
    walletIdLength: "Must be exactly 16 symbols",
    walletIdError: "Wallet ID is required",
    wrongMobileNumber: "Please enter a valid mobile money number.",
    paypalRedirection: "You will be redirected to the paypal website",
    selectProvider: "Select your payment method"
};
