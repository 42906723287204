import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import useStyles from "./styles";
import OrderRow from "./OrderRow";
import { theme as CTheme } from "../../../../styles/theme";
import CardFooterRow from "../CardFooterRow";
import palette from "../../../../styles/palette";

const OrdersCardMobile = ({ data, items, amountsData, theme }) => {
    const classes = useStyles();

    return (
        <>
            <Grid className={classes.cardHeader} sx={{ backgroundColor: theme?.primaryColor ? theme.primaryColor : palette.primary.main }} container direction="column">
                <Typography variant="b6">Détails de la Commande</Typography>
                <Typography variant="b7" style={{ fontWeight: 500 }}>Order details</Typography>
            </Grid>
            <Box
                sx={{
                    borderLeft: `1px solid ${CTheme.palette.border.default}`,
                    borderRight: `1px solid ${CTheme.palette.border.default}`,
                }}
            >
                <Grid
                    className={classes.columnNames}
                    container
                    direction="column"
                    justifyContent="center"
                >
                    <Grid container item justifyContent="space-between">
                        <Typography variant="b7" style={{ fontWeight: 500 }}>Déscription</Typography>
                        <Typography variant="b7" style={{ fontWeight: 500 }}>Montant</Typography>
                    </Grid>
                    <Grid container item justifyContent="space-between">
                        <Typography variant="t7">Description</Typography>
                        <Typography variant="t7">Amount</Typography>
                    </Grid>
                </Grid>
                {items.map((item) => (
                    <OrderRow
                        key={item?.particulars}
                        item={item}
                        currency={data?.currency}
                    />
                ))}
            </Box>
            <Box className={classes.footer} sx={{ backgroundColor: theme?.secondaryColor ? theme.secondaryColor : palette.background.grey }}>
                {amountsData.map((item) => (
                    <CardFooterRow item={item} key={item.name} />
                ))}
            </Box>
        </>
    );
};

export default OrdersCardMobile;
