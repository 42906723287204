export default {
    paymentButton: "PAYER / PAY",
    paymentPhoneError: "Votre numéro de portable est obligatoire",
    paymentNameError: "Votre nom est obligatoire",
    paymentMethodError: "Une option de paiement est obligatoire",
    paymentTerms: "Votre accord avec les termes et conditions est requis",
    thanksPageTitle1: "Merci d’avoir choisi",
    thanksPageTitle2: " comme mode de paiement !",
    providerTitle: "Fournisseur",
    instructionsTitle: "Instructions",
    thanksPageSubTitle1: "Votre paiement est en cours de traitement.",
    thanksPageSubTitle2: " Vous serez informé une fois la transaction terminée",
    thanksPageText:
        "Si vous avez payé via Mobile Money, il y a une petite chance \n" +
        "que vous ne receviez pas\n" +
        "la notification SMS de votre fournisseur. \n" +
        "Dans ce cas, vous pouvez manuellement\n" +
        "déclencher le paiement.",
    orangeInstructions:
        "Composez le 150*50# pour valider votre retrait au cas où vous n'auriez pas reçu de demande.",
    mtnInstructions:
        "Composez *126# pour valider votre retrait \n" +
        "au cas où vous n'auriez  pas reçu de demande.",
    euInstructions:
        "Il vous suffit de vous connecter à votre application mobile Express Union et de cliquer sur Autoriser et approuver \n" +
        "le retrait si vous n'avez pas reçu de notification par SMS\n" +
        "de la part d'Express Union.",
    yoomeeInstructions:
        "Connectez-vous à votre application mobile Yoomee Money, accédez à Cashout, cliquez sur Incoming Cashout Request et acceptez le retrait",
    backToShopButton: "Retour vers",
    backToMerchantSite: "Retour vers site du marchand",
    successfulTransaction: "Transaction réussi !",
    yourPaymentId: "Votre paiement",
    wasSuccessfullyTreated: "a été traité avec succès.",
    transactionFailed: "Transaction echoué !",
    hasFailed: "a echoué.",
    redirectMessage1: "Vous serez redirigé  ",
    redirectMessage2: " secondes vers le site marchand.",
    errorPageTitle: "Erreur.",
    errorPageSubtitle: "Une erreur s’est produite lors de la requête en cours.",
    mobileError: "Veuillez fournir un numéro mobile money valide.",
    paymentError:
        "Impossible de traiter le paiement : Sélectionnez un mode de paiement et fournissez un numéro mobile money valide.",
    generalError: "Échec  générale pendant le traitement de la transaction.",
    contactSupport: "Contacter l’équipe de support ",
    backButton: "Retour",
    walletIdLength: "Doit être exactement 16 symboles ",
    walletIdError: "L'identifiant de portefeuille est requis",
    wrongMobileNumber: "Veuillez entrer un numéro mobile money valide.",
    paypalRedirection: "Vous serez redirigé vers le site paypal",
    selectProvider: "Séléctionner un mode de paiement"
};
