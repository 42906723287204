import React, { useState, useEffect } from "react";

const useCountdownTimer = (initialTime, callback) => {
  const [time, setTime] = useState(initialTime);

  useEffect(() => {
    const timerId = setInterval(() => {
      if(time > 0) {
        setTime(prevTime => prevTime - 1)
      }

      time === 0 && callback()
      return clearInterval(timerId)
    }, 1000);

    return () => clearInterval(timerId);
  }, [initialTime, callback]);

  return time;
};

export default useCountdownTimer;
