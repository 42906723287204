import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "fit-content",
        maxWidth: 420,
        border: `1px solid ${theme.palette.primary.main}`,
    },
    cardHeader: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2.5),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
    },
    gridWrapper: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        border: `1px solid ${theme.palette.border.default}`,
    },
    cardTotal: {
        display: "flex",
        padding: theme.spacing(2.5, 3.5),
        justifyContent: "space-between",
        borderBottom: "none",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
        border: `2px solid ${theme.palette.primary.main}`,
    },
    footer: {
        backgroundColor: theme.palette.primary.main,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}));

export default useStyles;
