import React, {createContext, useEffect, useState} from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";


export const GlobalContext = createContext()
export const GlobalProvider = ({children}) => {

    const [searchResults, setSearchResults] = useState([]);
    const [load, setLoad] = useState(true);
    const [errorRequest, setErrorRequest] = useState({code: "", message: ""});
    const [language, changeLanguage] = useState("fr");
    const [paymentProviderName, setPaymentProviderName] = useState("");
    const [theme, setTheme] = useState()
    const [logo, setLogo] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        setLoad(true)
        axios.defaults.withCredentials = true;
        axios({
            method: "GET",
            baseURL: window._env_.REACT_APP_API_HOST,
            url: `/payment/api/ui/paymentDataInfo`,
            withCredentials: true
        })
            .then((resp) => {
                const allData = resp.data;
                setSearchResults(allData)
                setTheme(allData.merchantInfo.merchantColor);
                setLogo(allData.merchantInfo.merchantLogo.image);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    return navigate(`/unauthenticated`)
                }
            }).finally(() => setLoad(false))

    }, []);

    return (<GlobalContext.Provider
            value={{
                load,
                setLoad,
                searchResults,
                setSearchResults,
                errorRequest,
                setErrorRequest,
                language,
                changeLanguage,
                paymentProviderName,
                setPaymentProviderName,
                setTheme,
                theme,
                logo
            }}
        >
            {children}
        </GlobalContext.Provider>
    )

}







