import React, {useContext, useEffect, useState} from "react";
import {Grid, Link, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ButtonStyled from "../../components/ButtonStyled";
import ThanksPageGrid from "./ThanksPageGrid";
import OrderColumns from "./helpers/OrderColumns";
import {data} from "./helpers/mockData";
import {GlobalContext} from "../../context/GlobalContext";
import useTranslation from "../../customHooks/translations";
import useUI from "../../hooks/useUI";
import palette from "../../styles/palette";

const ThanksPage = () => {
    const classes = useStyles();
    const {searchResults, paymentProviderName, theme} = useContext(GlobalContext);
    const translations = useTranslation();
    const {isMobileMode} = useUI();
    const [time, setTime] = useState(11);

    const tick = () => {
        setTime(time - 1);
        if (time === 1) {
            return window.location.replace(
                `${searchResults?.fullReturnUrl}`
            );
        }
    };

    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });
    return (
        <Grid container item xl={8} direction="column">
            <Typography
                variant={isMobileMode ? "b5" : "b3"}
                component={"span"}
                className={classes.title}
            >{`${translations.thanksPageTitle1} ${paymentProviderName} ${translations.thanksPageTitle2}`}</Typography>
            <Typography
                variant={isMobileMode ? "t5" : "b4"}
                className={classes.subTitle1}
            >
                {translations.thanksPageSubTitle1}
            </Typography>
            <Typography
                variant={isMobileMode ? "t5" : "b4"}
                className={classes.subTitle2}
            >
                {translations.thanksPageSubTitle2}
            </Typography>
            <Typography
                variant={isMobileMode ? "t4" : "t5"}
                className={classes.text}
            >
                {translations.thanksPageText}
            </Typography>
            <ThanksPageGrid columns={OrderColumns} rows={data}/>
            <Link href={`${searchResults?.fullReturnUrl}`}>
                <ButtonStyled
                    style={{ backgroundColor: theme?.primaryColor ? theme.primaryColor : palette.primary.main }}
                    label={`${translations.backToShopButton} ${searchResults?.merchantInfo?.name}`}
                />
            </Link>
            <Typography
                variant={isMobileMode ? "t7" : "t4"}
                component={"span"}
                className={classes.redirectMessage}>
                {`${translations.redirectMessage1} ${time} ${translations.redirectMessage2}`}
            </Typography>
        </Grid>
    );
};

export default ThanksPage;

const useStyles = makeStyles((theme) => ({
    title: {
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingBottom: theme.spacing(2),
        },
    },
    subTitle1: {
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
    subTitle2: {
        paddingBottom: theme.spacing(3.5),
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingBottom: theme.spacing(2),
        },
    },
    text: {
        paddingBottom: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
            paddingBottom: theme.spacing(3),
        },
    },
    redirectMessage: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(1),
        },
    },
}));