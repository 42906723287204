import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import ButtonStyled from "../../components/ButtonStyled";
import { SUCCESS } from "../../constants/transactionStatus";
import useTranslation from "../../customHooks/translations";
import useCountdownTimer from "../../hooks/useCountDownTimer";
import useUI from "../../hooks/useUI";
import palette from "../../styles/palette";
import useStyles from "./styles";

const StatusPage = () => {
  const { search } = useLocation();
  const classes = useStyles();
  const translations = useTranslation();
  const { isMobileMode } = useUI();

  const results = new URLSearchParams(search);

  const transactionStatus = results.get("status");
  const returnUrl = results.get("returnUrl");
  const merchantReference = results.get("merchantReference");

  const redirectWhenTimeRunsOut = () => {
    if (returnUrl) {
      window.location.replace(returnUrl);
    }
  };

  const time = useCountdownTimer(10, redirectWhenTimeRunsOut);
  if (!transactionStatus) {
    return window.location.replace("/");
  }

  const isSuccess = transactionStatus === SUCCESS

  return (
    <div>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 5,
          mb: 3,
        }}
      >
        {isSuccess ? (
          <CheckCircleIcon
            color="success"
            sx={{ width: 60, height: 60, mb: 2 }}
          />
        ) : (
          <CancelIcon color="error" sx={{ width: 60, height: 60, mb: 2 }} />
        )}
        <Typography
          variant={isMobileMode ? "t5" : "b4"}
          sx={{
            color: isSuccess ? "#06971D" : "#E30613",
            mb: 2,
          }}
        >
          {isSuccess
            ? translations.successfulTransaction
            : translations.transactionFailed}
        </Typography>
        <Typography
          sx={{ mb: 2, width: "90%", textAlign: "center" }}
          variant={isMobileMode ? "t4" : "t5"}
        >
          {translations.yourPaymentId} {" "}
          {/* {translations.yourPaymentId} : <strong>{merchantReference}</strong>{" "} */}
          {isSuccess
            ? translations.wasSuccessfullyTreated
            : translations.hasFailed}
        </Typography>
      </Paper>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <a href={returnUrl}>
          <ButtonStyled
            style={{
              backgroundColor: palette.primary.main,
            }}
            label={translations.backToMerchantSite}
          />
        </a>
        <Typography
          variant={isMobileMode ? "t7" : "t4"}
          component={"span"}
          className={classes.redirectMessage}
        >
          {`${translations.redirectMessage1} ${time} ${translations.redirectMessage2}`}
        </Typography>
      </Box>
    </div>
  );
};

export default StatusPage;
