import React from "react";

export default [
    {
        id: 'provider',
        label: 'providerTitle',
        key: 'provider',
        type: 'string',
        align: "center",
    },
    {
        id: 'description',
        label: 'instructionsTitle',
        key: 'description',
        type: 'string',
        align: "left",
    },
];
