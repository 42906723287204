import React from "react";

import { theme } from "./styles/theme";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { ThemeProvider } from "@mui/material/styles";
import { GlobalProvider } from "./context/GlobalContext";

import Header from "./views/Header";
import Footer from "./views/Footer";
import OrdersPage from "./views/OrdersPage";
import ErrorPage from "./views/ErrorPage";
import ThanksPage from "./views/ThanksPage/index";

import GridWrapper from "./components/GridWrapper";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Error401Page from "./views/Error401Page";
import useUI from "./hooks/useUI";
import StatusPage from "./views/StatusPage";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
        maxWidth: "1230px",
        minHeight: "calc(100vh - 175px)",
    },
}));

function App() {
    const classes = useStyles();
    const matches = useMediaQuery(theme.breakpoints.up("xs"));
    const { isMobileMode } = useUI();

    return (
        <>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <GlobalProvider>
                        <Header />
                        <GridWrapper justifyContent>
                            <Grid
                                item
                                component="main"
                                xl={12}
                                margin="auto"
                                pt={isMobileMode ? 1 : 6.5}
                                pl={matches ? 0 : 7.5}
                                className={classes.wrapper}
                                sx={{
                                    [theme.breakpoints.down("md")]: {
                                        minHeight: "calc(100vh - 211px)",
                                    },
                                }}
                            >
                                <Routes>
                                    <Route
                                        exact
                                        path="/"
                                        element={<OrdersPage />}
                                    />
                                    <Route
                                        path="/thanks-page"
                                        element={<ThanksPage />}
                                    />
                                    <Route
                                        path="/error-page"
                                        element={<ErrorPage />}
                                    />
                                    <Route
                                        path="/unauthenticated"
                                        element={<Error401Page />}
                                    />
                                    <Route
                                        path="/status-page"
                                        element={<StatusPage />}
                                    />
                                </Routes>
                            </Grid>
                        </GridWrapper>
                        <Footer />
                    </GlobalProvider>
                </BrowserRouter>
            </ThemeProvider>
        </>
    );
}

export default App;
