import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./phoneNumber.css";
import { getIn } from "formik";
import useTranslation from "../customHooks/translations";



export const PhoneNumberInput = ({name, formik, defaultValue, ...rest }) => {
    const [isError, setIsError] = useState(true)
    const translation = useTranslation();
    useEffect(() => {
        const number = getIn(formik?.values, name);
        if (number?.length === 0) {
            setIsError(true);
        }
        if (number?.length > 5) {
            setIsError(false);
        }
    }, [formik?.values]);

    const onValueChange = (phoneNumber) => {
        return formik.setFieldValue(name, phoneNumber);
    };

    return (
        <div>
            <PhoneInput
                {...rest}
                country={'cm'}
                onlyCountries={["cm"]}
                disableDropdown
                autoFormat={true}
                masks={{cm: '. .. .. .. ..'}}
                placeholder="+237 e.g 6########"
                fullWidth
                isValid={(value) => {
                    if (value.length === 0) {
                        return `${translation.paymentPhoneError}`;
                    }
                    if (getIn(formik.errors, name)) {
                        return `${getIn(formik.errors, name)}`;
                    }
                }}
                id={name}
                name={name}
                margin="dense"
                variant="outlined"
                preferredCountries={['cm']}
                countryCodeEditable={false}
                InputLabelProps={{
                    shrink: true,
                    required: true,
                }}
                defaultValue={defaultValue}
                value={getIn(formik?.values, name)}
                onChange={onValueChange}
                onBlur={formik?.handleBlur}
                error={
                    getIn(formik?.touched, name) &&
                    !!getIn(formik?.errors, name)
                }
                helperText={
                    getIn(formik?.touched, name) && getIn(formik?.errors, name)
                }
                containerStyle={{
                    width: "100%",
                    height: 48,
                    borderRadius: 0,
                    backgroundColor: "#ffffff",
                    marginBottom: 10
                }}
                inputStyle={{
                    height: 48,
                    width: "100%",
                    fontFamily: 'IBM Plex Sans, sans-serif',
                    fontSize: '1rem',
                    border: isError
                        ? `0.5px solid #B8B7B7`
                        : `1px solid #0AA5B7`,
                    borderRadius: 3,
                    backgroundColor: "#ffffff",
                }}
                buttonStyle={{
                    height: 48,
                    marginRight: 12,
                    backgroundColor: "#ffffff",
                    border: isError
                        ? `0.5px solid #B8B7B7`
                        : `1px solid #0AA5B7`,
                }}
                dropdownStyle={{
                    borderRadius: 0,
                    backgroundColor: "#ffffff",
                }}
            />
        </div>
    );
};
