import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 457,
        margin: "0 auto",
        paddingRight: theme.spacing(2),
    },
    radio: {
        "& .MuiSvgIcon-root": {
            height: 15,
            width: 15,
            color: theme.palette.primary.main,
        }
    },
    rootForm: {
        border: `1px solid ${theme.palette.border.default}`,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
        maxWidth: 457,
    },
    fieldTitle: {
        marginBottom: theme.spacing(1),
        fontSize: "1.25rem",
        fontWeight: 600,
        lineHeight: "1.5rem",
    },
    fieldTitleSecondary: {
        color: theme.palette.text.primary,
        paddingBottom: theme.spacing(2),
        fontSize: "1rem !important",
    },
    icon: {
        height: 30,
        width: "auto",
    },
    marker: {
        fontWeight: "bold",
        fontSize: 20,
        color: theme.palette.red,
        transform: "rotate(0.1deg)",
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formHeader: {
        maxWidth: 457,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.default,
    },
    radioBlockWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between"
    },
    fieldset: {
        display: 'flex !important',
        flexDirection: 'row !important',
        flexWrap: 'wrap !important',
        alignItems: 'center',
        justifyContent: "space-between"
    },

    inputWrapper: {
        paddingBottom: theme.spacing(4),
    },
    input: {
        minWidth: "100%",
        '& .MuiInputBase-input': {
            border: `0.5px solid ${theme.palette.primary.main},`,
            borderRadius: 0,
            padding: 0,
            paddingLeft: theme.spacing(1),
            fontSize: "1rem",
            width: "100%",
            height: 48,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            '& fieldset': {
                border: `0.5px solid ${theme.palette.border.default}`,
                borderRadius: 3,
            },
            '&:hover fieldset': {
                border: `1px solid ${theme.palette.primary.main}`,
            },
            '&.Mui-focused fieldset': {
                border: `1px solid ${theme.palette.primary.main}`,
            },
        },
    },
    checkBox: {
        paddingBottom: theme.spacing(0.5),
        '& .MuiCheckbox-root': {
            fontSize: "1.5rem",
            color: theme.palette.primary.main,
        },
    },
    checkBoxWrapper: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(4),
        marginBottom: theme.spacing(5),
    },
    link: {
        '& .MuiLink-root': {
            color: theme.palette.text.secondary,
            textDecoration: "none",
        }
    },
    button: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(1.5),
    }


}));

export default useStyles;