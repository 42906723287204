import React from "react";
import { Alert as MuiAlert, AlertTitle } from "@mui/material";

const Alert = ({
  title,
  description,
  onClose,
  status = "",
  variant = "outlined",
  titleProps,
  ...rest
}) => {
  return (
    <MuiAlert
      status={status}
      variant={variant}
      onClose={onClose}
      {...rest}
    >
      <AlertTitle sx={{ fontWeight: "bold" }} {...titleProps}>{title}</AlertTitle>
      {description}
    </MuiAlert>
  );
};

export default Alert;
